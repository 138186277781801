import React, { useState,useEffect } from 'react'
import { IoFastFood } from 'react-icons/io5'
import { categories } from '../utils/data'
import { motion } from 'framer-motion'
import RowContainer from './RowContainer'
import { useStateValue } from '../context/StateProvider'

const MenuContainer = () => {
    const [{foodItems},dispatch] = useStateValue()
    const [filter,setFilter] = useState('chicken')

    useEffect(() =>{},[filter])

  return (
    <section className='w-full my-6' id='menu'>
        <div className="w-full flex flex-col items-center justify-center">
            <p className="text-2x1 font-semibold capitalize text-headingColor relative before:absolute before:rounded-lg before:content before:w-16 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-orange-400 to-orange-600 transition-all ease-in-out duration-100 mr-auto">
                Our Hot Dishes
            </p>
            <div className="w-full flex items-center justify-start lg:justify-center py-6 gap-8 mt-6 overflow-x-scroll scrollbar-none">
                {
                categories && categories.map((item) =>(
                <motion.div whileTap={{ scale:0.75 }} key={item.id} className={`group ${filter === item.urlParamName ? 'bg-cartNumBg': 'bg-white'} w-24 min-w-[94px] h-28 cursor-pointer rounded-lg drop-shadow-lg flex flex-col gap-3 items-center hover:bg-cartNumBg justify-center`}
                onClick={ () => setFilter(item.urlParamName) }
                >
                    <div className={`w-10 h-10  shadow-lg ${filter === item.urlParamName ? 'bg-white' : 'bg-cartNumBg'} rounded-full group-hover:bg-card flex items-center justify-center`}>
                        <IoFastFood
                            className={`${filter === item.urlParamName ? 'text-textColor' : 'text-white'}group-hover:text-white text-lg`}
                        />
                    </div>
                    <p className={`${filter === item.urlParamName ? 'text-white':'text-textColor'} group-hover:text-white text-sm`}>
                        {item.name}
                    </p>
                </motion.div>
                ))}
            </div>
        <div className="w-full">
            <RowContainer flag={false} data={ foodItems?.filter((item) => item.category === filter ) }/>
        </div>
        </div>
    </section>
  )
}

export default MenuContainer